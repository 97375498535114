import React from "react";
import { Button } from "@my-scoot/component-library-legacy";
import { useHistory } from "react-router-dom";

import style from "./EmptyMemberView.module.css";
import { ReactComponent as ViewListingIcon } from "assets/vectors/ViewListing.svg";

const EmptyMemberView = () => {
  const history = useHistory();
  const addMember = () => {
    history.push("/organization/member/add");
  };
  return (
    <div className={style.container}>
      <ViewListingIcon />
      <div className={style.title}>No members have been added yet!</div>
      <div className={style.content}>
        Use “Add a Member” and choose from the predefined roles as per the
        desired permission you want to assign to a staff member and track their
        records and contributions.
      </div>
      <Button onClick={addMember}>Add Member</Button>
    </div>
  );
};

export default EmptyMemberView;
