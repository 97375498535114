import { maxValue, minValue, required } from "common/validate";
import constants from "constants/constants";
import { questions_type_mapping } from "constants/customerQuestions.constants";
import { isInternationalTimezone } from "utils/AuthUtil";
import { composeValidators, is_empty } from "utils/validations";
import { PART_PAYMENT_FORM_KEYS } from "./Components/PaymentPlanWithInstallments/PaymentPlanWithInstallments.data";
import { isInstallmentsPlanType } from "./Components/PaymentPlanWithInstallments/utils/PaymentPlanWithInstallments.utils";
import { checkIsArrayEmpty } from "@my-scoot/exly-react-component-lib";

export const getDescriptionPlaceholder = (type) => {
  switch (type) {
    case constants.scheduleTypesId.one_on_one_class:
    case constants.scheduleTypesId.group_class:
    case constants.scheduleTypesId.rolling_class:
      return "Eg, What is the course about? What are its benefits? Who all can attend these classes?";
    case constants.scheduleTypesId.workshop:
      return "Eg, What is the workshop about? What are its benefits? Who all can attend this workshop?";
    case constants.scheduleTypesId.one_on_one_appointment:
      return "Eg, What is the session about? What are its benefits? Who all can book this session?";
    case constants.scheduleTypesId.no_schedule:
      return "Eg, What is the page or product about? Any details the customer should know?";
    case constants.scheduleTypesId.branded_community:
      return "A short description about your community...";
    case constants.scheduleTypesId.telegram:
      return "What are the benefits of joining this community? Who all can join this community?";
    case constants.scheduleTypesId.merchandise:
      return "What are the benefits of this product? For whom all this product will be suitable?";
    case constants.scheduleTypesId.whatsapp:
      return "Eg, What is the page or product about? Any details the customer should know?";
    case constants.scheduleTypesId.webinar:
      return "Eg, What is the webinar about? What are its benefits? Who all can attend this webinar?";
    default:
      return "";
  }
};

export const getTooltipDescription = (type) => {
  switch (type) {
    case constants.scheduleTypesId.one_on_one_class:
    case constants.scheduleTypesId.group_class:
    case constants.scheduleTypesId.rolling_class:
      return "Add a description to let your students know important details about the class.";
    case constants.scheduleTypesId.webinar:
      return "Add a description to let your students know important details about the webinar.";
    case constants.scheduleTypesId.workshop:
      return "Add a description to let your customers know important details about the workshop.";
    case constants.scheduleTypesId.one_on_one_appointment:
      return "Add a description to let your customers know important details about the appointment session.";
    case constants.scheduleTypesId.no_schedule:
      return "Add a description to let your customers know important details about the page or product.";
    case constants.scheduleTypesId.branded_community:
      return "Add a description to let your customers know important details about the community.";
    case constants.scheduleTypesId.telegram:
      return "Add a description to let your customers know important details about your community.";
    case constants.scheduleTypesId.merchandise:
      return "Add a description to let your customers know important details about the product.";
    case constants.scheduleTypesId.whatsapp:
      return "Add a description to let your customers know important details about the page or product.";
    default:
      return "";
  }
};

export const getDescriptionModalTitle = (type) => {
  switch (type) {
    case constants.scheduleTypesId.no_schedule:
      return "Page Description";
    case constants.scheduleTypesId.workshop:
      return "Workshop Description";
    case constants.scheduleTypesId.one_on_one_appointment:
      return "Session Description";
    case constants.scheduleTypesId.group_class:
    case constants.scheduleTypesId.rolling_class:
    case constants.scheduleTypesId.one_on_one_class:
      return "Class Description";
    case constants.scheduleTypesId.merchandise:
      return "Product Description";
    case constants.scheduleTypesId.branded_community:
    case constants.scheduleTypesId.telegram:
    case constants.scheduleTypesId.whatsapp:
      return "Community Description";
    case constants.scheduleTypesId.webinar:
      return "Webinar Description";
    default:
      return "Description";
  }
};

/**
 * checker to check if fields in the add Question form are filled properly
 * @returns boolean true if validations are true else false
 */
export const addQuestionsValidations = (questionObj, notify) => {
  /**
   * Adding this check to make sure a customer selects atleast one option when adding a question with single or multiple options
   */

  const { type, choices, question } = questionObj;
  const isQuestionIsOfTypeOptions = questions_type_mapping[type]?.hasChoices;

  // filtering out choices and seeing if the choices array is empty or not
  const areChoicesEmpty =
    checkIsArrayEmpty(choices) || choices.every((choice) => !choice);

  const isAnyOptionEmpty = choices.some((choice) => !choice);

  if (isQuestionIsOfTypeOptions && areChoicesEmpty) {
    notify("Please add options", "error");
    return false;
  }

  if (isQuestionIsOfTypeOptions && isAnyOptionEmpty) {
    notify("Please fill all the options", "error");
    return false;
  }

  if (is_empty(question)) {
    notify("Please add a question", "error");
    return false;
  }

  return true;
};

export const validators = {
  price: (value, allValues) => {
    const { [PART_PAYMENT_FORM_KEYS.payment_plan_type]: paymentPlanType } =
      allValues;
    const isInstallmentsPlan = isInstallmentsPlanType(paymentPlanType);

    // remove validation if installments payment plan disabled because fields are still visible in disabled state
    // not saving installments fields data in disabled state and allowing creator to go to next step even having validation errors in fields
    // repro: create installments with validation errors -> turn off installments plan toggle -> save and next -> you'll be able to save form
    if (!isInstallmentsPlan) return;

    return composeValidators(
      required,
      minValue(0),
      maxValue(
        isInternationalTimezone()
          ? constants.max_international_price
          : constants.max_domestic_price
      )
    )(value);
  },
  installmentInternationalPrice: (value, allValues) => {
    const { [PART_PAYMENT_FORM_KEYS.payment_plan_type]: paymentPlanType } =
      allValues;
    const isInstallmentsPlan = isInstallmentsPlanType(paymentPlanType);

    // remove validation if installments payment plan disabled because fields are still visible in disabled state
    // not saving installments fields data in disabled state and allowing creator to go to next step even having validation errors in fields
    // repro: create installments with validation errors -> turn off installments plan toggle -> save and next -> you'll be able to save form
    if (!isInstallmentsPlan) return;

    return composeValidators(
      required,
      minValue(0),
      maxValue(constants.max_international_price)
    )(value);
  },
  internationalPrice: composeValidators(
    required,
    minValue(0),
    maxValue(constants.max_international_price)
  ),
  internationalPriceWithAutoDebit: composeValidators(
    required,
    minValue(1),
    maxValue(constants.max_international_price)
  ),
};
