import constants from "constants/constants";

export const course_availability_options = [
  {
    label: "All the recordings",
    value: "1",
    tooltipText:
      "All recordings will be shared with the customer, regardless of when they book, how many sessions have passed by, or how many sessions they have attended.",
  },
  {
    label: "Only recordings held after booking",
    value: "2",
    tooltipText:
      "Only those recordings will be shared with the customer, that have occurred after the customer booked.",
  },
];

export const access_visibility_address_type = [
  constants.address_type_enum.zoom,
  constants.address_type_enum.exly_connect_by_zoom,
];
