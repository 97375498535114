import React from "react";
import { Card, Dropdown } from "@my-scoot/component-library-legacy";
import useStyles from "../schedule-v2.module";
import dataProvider from "data/dataProvider";
import api from "data/APIs";
import classnames from "classnames";
import { useNotifications } from "utils/hooks";
import { Field } from "react-final-form";
import ExlyCheckbox from "common/form/ExlyCheckbox";
import { is_empty } from "utils/validations";
import EmptyMemberView from "./EmptyMemberView/EmptyMemberView";

export default function AssignMember({
  uuid,
  selectedMember,
  form,
  formState,
  host_org_uuid,
}) {
  const classes = useStyles();
  const [members, setMembers] = React.useState([]);
  const [rawMembers, setRawMembers] = React.useState([]);
  const [selectedMemberId, setSelectedMemberId] = React.useState(null);
  const [loadingMembers, setLoadingMembers] = React.useState(false);

  const { pushNotification } = useNotifications();

  const handleChangee = (memberID) => {
    setSelectedMemberId(memberID);
    dataProvider
      .custom_request(api.assign_listings, "POST", {
        org_uuid: memberID,
        listing_uuid: [uuid],
      })
      .then(() => {
        pushNotification("Offering assigned successfully", {
          variant: "outlined",
          color: "fine_pine",
        });
        form.change("org_uuid", memberID);
        form.change(
          "org_member_name",
          rawMembers.find((m) => m.org_uuid === memberID)?.member_name
        );
      })
      .catch((err) => {
        console.log(err);
        pushNotification(err.message, {
          variant: "outlined",
          color: "coral_red",
        });
      });
  };

  const fetchOrgMembers = () => {
    setLoadingMembers(true);
    dataProvider
      .custom_request(api.get_assignee_list, "GET", { page: "all" })
      .then((response) => {
        setRawMembers(response.data.assignment_members);
        const membersList = [];

        if (
          response.data.assignment_members.map((member, index) => {
            membersList.push({
              id: index,
              label: member.member_name,
              value: member.org_uuid,
            });
          })
        )
          setMembers(membersList);
      })
      .catch((err) => console.log("getOrgMembers err", err))
      .finally(() => setLoadingMembers(false));
  };

  React.useEffect(() => {
    fetchOrgMembers();
  }, []);

  React.useEffect(() => {
    setSelectedMemberId(selectedMember);
  }, [selectedMember]);

  React.useEffect(() => {
    if (
      formState.values?.org_uuid &&
      members &&
      members.length &&
      members?.[0]?.id != -1
    ) {
      const newMembers = [
        {
          id: -1,
          label: "Select a member",
          value: "None",
        },
        ...members,
      ];

      setMembers(newMembers);
    }
  }, [formState.values.org_uuid, members]);

  return (
    <Card
      shadow="shadow_md"
      variant="outlined"
      CardClassName={classnames(classes.marginBottom, classes.paddedCard)}
    >
      <div className={`mb-2 ${classes.cardTitle}`}>
        Assign this offering (Optional)
      </div>

      <Dropdown
        helperTextClassName={classes.helperTextClassName}
        name="org_uuid"
        label="Organization"
        value={selectedMemberId}
        placeholder="Select Member"
        fullWidth="100%"
        options={members}
        size="small"
        handleChange={handleChangee}
        renderMenuListFooter={() =>
          is_empty(rawMembers) && !loadingMembers ? <EmptyMemberView /> : <></>
        }
      />

      <div className={classnames(classes.pricingMutedText, "mb-3")}>
        Once you assign this offering to a member, all the relevant information
        (bookings, customers, comms, etc.) will get synced with that
        {" member's "}
        page.
      </div>
      {selectedMemberId &&
        selectedMemberId !== host_org_uuid && // selectedMemberId === host_org_uuid means listing is assigned to main user
        selectedMemberId !== -1 &&
        selectedMemberId !== "None" && (
          <Field
            id="metadata.include_org_contact_info"
            name="metadata.include_org_contact_info"
            component={ExlyCheckbox}
            size="large"
            outerWrapperClassName="align-items-start"
            label="Share contact details of the assigned member in the booking confirmation email to end user"
            wrapperClassName={classes.pricingCheckboxWrap}
          />
        )}
    </Card>
  );
}
