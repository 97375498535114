export const FILE_TYPES = {
  image: "image",
  video: "video",
  doc: "doc",
};

export const FILE_UPLOAD_OPTIONS = [
  {
    label: "Image",
    value: FILE_TYPES.image,
  },
  {
    label: "Video",
    value: FILE_TYPES.video,
  },
  {
    label: "Document",
    value: FILE_TYPES.doc,
  },
];
