import constants from "constants/constants";
import { api, dataProvider } from "data";
import { DRM_MEDIA_TYPE } from "schedule-v2/data";

// for future add async await helpers

export const fetchRecordedContentDetails = (id, update_schedule = true) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(`${api.get_recordedcontent_details}${id}`, "GET", {
        update_schedule,
      })
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error("Failure during fetching recorded content details");
        resolve(data);
      })
      .catch(reject);
  });

export const fetchRecordedContentComments = (sub_category__uuid, pageNumber) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(
        `${api.comments_reactions.get_comments_on_recorded_content}`,
        "GET",
        {
          page: pageNumber,
          sub_category__uuid,
        }
      )
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error("Failure during fetching blog comments");
        resolve(data);
      })
      .catch(reject);
  });

export const postCommentOnRecordedContent = (sub_category_uid, comment) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(
        `${api.comments_reactions.add_comment_on_recorded_content}`,
        "POST",
        {
          sub_category_uid,
          comment,
        }
      )
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error("Error while posting a comment on recorded content");
        resolve(data);
      })
      .catch(reject);
  });

export const fetchCommentRepliesInRecordedContent = (comment_uid) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(
        `${api.comments_reactions.get_replies_in_recorded_content}`,
        "GET",
        {
          comment_uid,
        }
      )
      .then(({ status, data }) => {
        const { replies, ...rest } = data;
        if (status !== 200)
          throw new Error("Failure during fetching replies on comments");
        resolve({ results: replies, ...rest });
      })
      .catch(reject);
  });

export const postReplyOnCommentInRecordedContent = (
  comment_id,
  comment,
  additionParams = {}
) =>
  new Promise((resolve, reject) => {
    const { sub_category_uid } = additionParams;
    dataProvider
      .custom_request(
        `${api.comments_reactions.add_comment_on_recorded_content}`,
        "POST",
        {
          sub_category_uid,
          comment,
          reply_to: comment_id,
        }
      )
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error(
            "Error while posting a reply on comment in recorded content"
          );
        resolve(data);
      })
      .catch(reject);
  });

export const updateStatusOfReplyOnContent = (
  comment_uuid,
  status = constants.commentStatus.INACTIVE
) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(
        `${api.comments_reactions.update_comment_on_recorded_content}`,
        "POST",
        {
          comment_uuid,
          updated_values: { status },
          reply_to: comment_uuid,
        }
      )
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error(
            "Failure during updating reply status in recorded content"
          );
        resolve(data);
      })
      .catch(reject);
  });

export const updateStatusOfCommentOnContent = (
  comment_uuid,
  status = constants.commentStatus.INACTIVE
) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(
        `${api.comments_reactions.update_comment_on_recorded_content}`,
        "POST",
        {
          comment_uuid,
          updated_values: { status },
        }
      )
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error(
            "Failure during updating comment status in recorded content"
          );
        resolve(data);
      })
      .catch(reject);
  });

export const postLikeOnCommentInRecordedContent = (comment_uid) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(
        `${api.comments_reactions.add_reaction_on_comment_recorded_content}`,
        "POST",
        {
          comment_uid,
        }
      )
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error(
            "Error while posting a reaction on comment in recorded content"
          );
        resolve(data);
      })
      .catch(reject);
  });

export const fetchReactionListing = (comment_uid) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(`${api.comments_reactions.get_reaction_listing}`, "GET", {
        comment_uid,
      })
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error("Failure during fetching reaction listing");
        resolve(data);
      })
      .catch(reject);
  });

export const fetchMediaLibraryDrmVideos = (payload) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(`${api.media_library.get_media_library}`, "GET", {
        ...payload,
        media_type: DRM_MEDIA_TYPE,
      })
      .then(({ status, data }) => {
        if (status !== 200)
          throw new Error("Failure during fetching drm videos");
        resolve({ data: data.library, total: data.total });
      })
      .catch(reject);
  });

export const fetchVdoCipherVideoOtp = (recorded_content_uuid) =>
  new Promise((resolve, reject) => {
    dataProvider
      .custom_request(`${api.media_library.get_vdo_cipher_video_otp}`, "POST", {
        recorded_content_uuid,
      })
      .then(({ status, data, message }) => {
        if (status !== 200)
          throw new Error(
            `${message}: Failure during fetching drm video OTP / Verification Code `
          );
        resolve(data);
      })
      .catch(reject);
  });
