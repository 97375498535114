import React from "react";
import classnames from "classnames";
import { Switch, Radio } from "@my-scoot/component-library-legacy";
import Tooltip from "ui/modules/Tooltip";
import { access_visibility_offering_types } from "constants/schedule";
import {
  access_visibility_address_type,
  course_availability_options,
} from "./ShareRecording.constant";
import styles from "./ShareRecording.module.css";

const ShareRecording = (props) => {
  const {
    isAutoRecordable,
    recordingAccessControl,
    isAccessControlEditable,
    addressType,
    form,
    type,
  } = props;

  const handleCustomerRecordingAccessChange = (e) => {
    form.change("recording_accessibility", e.target?.value);
  };

  // Course recording availibility is only allowed for a few offering types,
  // and only when user selects the option of zoom meeting as location.
  const showRecordingAvailability =
    access_visibility_offering_types.includes(type) &&
    access_visibility_address_type.includes(addressType);

  return (
    <>
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <div
          className={classnames(
            "d-flex align-items-baseline",
            styles.single_label_info_style
          )}
        >
          <div className={styles.share_recording_text_style}>
            Share Recording with customers
          </div>
          <Tooltip
            text={
              "This feature enables you to share the zoom recording(s) of your session(s) with your customers. You can further select the accessibility of those recordings as well."
            }
          />
        </div>
        <Switch
          checked={isAutoRecordable}
          noIcon
          size="large"
          onChange={() => {
            form.change("is_auto_recordable", !isAutoRecordable);
          }}
        />
      </div>
      {isAutoRecordable && showRecordingAvailability && (
        <div>
          <div className={styles.share_recording_sub_text}>
            Which recordings will a customer get access to?
          </div>
          <div className={styles.radio_options_style}>
            {course_availability_options.map((option) => (
              <div key={option.value} className={styles.single_row_style}>
                <Radio
                  value={option.value}
                  checked={+recordingAccessControl === +option.value}
                  onChange={(e) => handleCustomerRecordingAccessChange(e)}
                  disabled={!isAccessControlEditable}
                />
                <div className={styles.single_label_info_style}>
                  <div className={styles.label_style}>{option.label}</div>
                  <Tooltip text={option.tooltipText} />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ShareRecording;
