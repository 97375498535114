import { required } from "common/validate";
import { composeValidators, is_empty } from "utils/validations";

export const variantFormKeys = {
  TYPE: "type",
  CUSTOM_TYPE: "customType",
  OPTIONS: "options",
};

export const DEFAULT_VARIANT_TYPE_OPTIONS = ["Size", "Color", "Style"];
export const CUSTOM_VARIANT_TYPE = "Custom";

export const VARIANT_OPTIONS = [
  {
    label: DEFAULT_VARIANT_TYPE_OPTIONS[0],
    value: DEFAULT_VARIANT_TYPE_OPTIONS[0],
  },
  {
    label: DEFAULT_VARIANT_TYPE_OPTIONS[1],
    value: DEFAULT_VARIANT_TYPE_OPTIONS[1],
  },
  {
    label: DEFAULT_VARIANT_TYPE_OPTIONS[2],
    value: DEFAULT_VARIANT_TYPE_OPTIONS[2],
  },
  { label: CUSTOM_VARIANT_TYPE, value: CUSTOM_VARIANT_TYPE },
];

export const validators = {
  type: (variantsArray, variantIndex) =>
    composeValidators(
      required,
      (value) =>
        variantsArray.findIndex(
          (variant, index) =>
            !!(variant?.type && variant?.type === value && variantIndex > index)
        ) !== -1 && `Type "${value}" is already selected`
    ),
  options: (value) => is_empty(value) && "Required",
};
