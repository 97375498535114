import constants from "constants/constants";
import React from "react";
import { Field } from "react-final-form";
import { ExlyDropdown, ExlyInput } from "../../common/form";
import useStyles from "../schedule-v2.module";
import { required, maxValue, minValue } from "../../common/validate";
import InteractiveTooltipWrapper from "./InteractiveTooltip";
import { is_desktop } from "utils/Utils";
import { composeValidators } from "utils/validations";

/**
 * Keeping separate options for both appointment types
 */
export const durationOptionsAppointment = [
  {
    id: 0,
    label: "Select",
    value: 0,
  },
  {
    id: 1,
    label: "30 minutes",
    value: 30,
  },
  {
    id: 2,
    label: "45 minutes",
    value: 45,
  },
  {
    id: 3,
    label: "60 minutes",
    value: 60,
  },
  {
    id: 4,
    label: "120 minutes",
    value: 120,
  },
  {
    id: 5,
    label: "Custom",
    value: "",
  },
];

const durationOptions = [
  {
    id: 1,
    label: "30 minutes",
    value: 30,
  },
  {
    id: 2,
    label: "45 minutes",
    value: 45,
  },
  {
    id: 3,
    label: "60 minutes",
    value: 60,
  },
  {
    id: 4,
    label: "120 minutes",
    value: 120,
  },
  {
    id: 5,
    label: "Custom",
    value: "",
  },
];

export default function DurationSelector({
  duration,
  form,
  request_groups,
  type,
  tooltipText,
  tooltipWeight,
  availability_choice,
}) {
  const classes = useStyles({ is_desktop });
  /**
   * returning options based type as we have different options for one to one selector return select only for not sure type appointment
   */
  const durationOptionsToShow =
    availability_choice === constants.avaibility_type.not_decided &&
    type === constants.scheduleTypesId.one_on_one_appointment
      ? durationOptionsAppointment
      : durationOptions;

  const handleChange = (value) => {
    if (value) {
      form.change("duration", value);
    }

    if (
      type === constants.scheduleTypesId.workshop ||
      type === constants.scheduleTypesId.one_on_one_appointment
    ) {
      if (request_groups[0]) {
        let customRequestsGroups = [...request_groups[0]];
        customRequestsGroups.forEach((item, index) => {
          customRequestsGroups[index].duration = value;
        });

        form.change("request_groups", [customRequestsGroups]);
      }
    } else if (
      type === constants.scheduleTypesId.one_on_one_class ||
      type === constants.scheduleTypesId.group_class ||
      type === constants.scheduleTypesId.rolling_class
    ) {
      let customRequestsGroups = [...request_groups];
      customRequestsGroups.forEach((item, index) => {
        let customRequests = [...customRequestsGroups[index]];
        customRequests.forEach((item, index) => {
          customRequests[index].duration = value;
        });

        customRequestsGroups[index].requests = customRequests;
      });

      form.change("request_groups", customRequestsGroups);
    }
  };

  const returnSelectedOption = (value) => {
    return durationOptionsToShow.find((option) => option.value === value);
  };

  return (
    <>
      <div className={`mb-2 ${classes.cardTitle}`}>
        {availability_choice === constants.avaibility_type.not_decided
          ? "Set duration of 1 slot "
          : "Set duration of each"}{" "}
        {[
          constants.scheduleTypesId.rolling_class,
          constants.scheduleTypesId.one_on_one_class,
          constants.scheduleTypesId.group_class,
        ].includes(type)
          ? "class"
          : "session"}
        {availability_choice === constants.avaibility_type.not_decided
          ? " (Optional)"
          : "*"}
        <InteractiveTooltipWrapper.Tooltip
          weight={tooltipWeight || "3"}
          content={tooltipText}
          rightAlign={true}
        />
      </div>
      <Field
        name="duration"
        component={ExlyDropdown}
        fullWidth
        size="small"
        options={durationOptionsToShow}
        onChange={handleChange}
        placeholder="Eg, 45 Minutes"
        format={(value) => {
          const option = returnSelectedOption(value);

          if (option) {
            return value;
          }

          return "";
        }}
      />
      {(duration === "" || !returnSelectedOption(duration)) && (
        <div className="mt-2 d-flex align-items-center">
          <Field
            name="duration"
            component={ExlyInput}
            type="number"
            onChange={handleChange}
            validate={composeValidators(required, maxValue(1440), minValue(1))}
          />
          <span className="ml-2">minutes</span>
        </div>
      )}
    </>
  );
}
