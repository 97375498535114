import React, { useEffect } from "react";
import { Field, useForm, useFormState } from "react-final-form";

import { InteractiveTooltipWrapper } from "schedule-v2/components";
import { ExlyDropdown, ExlyInput } from "common/form";
import { Switch } from "@my-scoot/component-library-legacy";
import { ReactComponent as TipsAndUpdatesIcon } from "assets/vectors/tips_and_updates.svg";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useStyles from "../../../schedule-v2.module";
import classnames from "classnames";
import {
  answer_types,
  question_initial_state,
  question_placement_types,
} from "./questionFormConstants";
import {
  questions_type_mapping,
  questions_placement_mapping,
} from "constants/customerQuestions.constants";

const QuestionsForms = (props) => {
  const formState = useFormState();
  const form = useForm();
  const is_desktop = useDesktopMediaQuery();
  const classes = useStyles({ is_desktop });

  useEffect(() => {
    if (props?.record) {
      const edit_state = {
        question: props?.record?.question,
        type: props?.record?.type,
        question_placement: props?.record?.question_placement,
        is_mandatory: props?.record?.is_mandatory,
        choices: props?.record?.choices,
        validations: props?.record?.validations,
      };

      form.change("questionObj", { ...edit_state });
    } else {
      form.change("questionObj", { ...question_initial_state });
    }
  }, []);

  const renderTypeSpecificValidation = (question) => {
    if (
      question?.type &&
      questions_type_mapping[question?.type]?.typeSpecificValidation
    ) {
      return questions_type_mapping[question?.type]?.typeSpecificValidation(
        question
      );
    }
    return null;
  };

  return (
    <>
      <InteractiveTooltipWrapper>
        <div className={classes.fieldWrapper}>
          <div className={classes.questionsTitle}>Question</div>
          <Field
            name="questionObj.question"
            placeholder="Type in your question..."
            size="small"
            style={{ width: "100%" }}
            component={ExlyInput}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <div className={classes.questionsTitle}>Answer Type</div>
          <Field
            name="questionObj.type"
            fullWidth="100%"
            options={answer_types()}
            size="small"
            style={{ width: "100%" }}
            component={ExlyDropdown}
          />
        </div>
        {renderTypeSpecificValidation(formState?.values?.questionObj)}
        <div className={classes.fieldWrapper}>
          <div className={classes.questionsTitle}>Question Placement</div>
          <Field
            name="questionObj.question_placement"
            fullWidth="100%"
            options={question_placement_types}
            size="small"
            style={{ width: "100%" }}
            component={ExlyDropdown}
          />
        </div>
        <div className={classes.switchWrapper}>
          <Switch
            checked={
              formState?.values?.questionObj?.is_mandatory
                ? formState?.values?.questionObj?.is_mandatory
                : false
            }
            size="large"
            onChange={() => {
              form.change(
                "questionObj.is_mandatory",
                !formState?.values?.questionObj?.is_mandatory
              );
            }}
            color="success"
          />
          <span>Is Mandatory</span>
        </div>
        {formState?.values?.questionObj?.question_placement ===
          questions_placement_mapping.before_booking && (
          <div className={classnames(classes.questionsTip, classes.tipPadding)}>
            <div className={!is_desktop && classes.svgWrapper}>
              <TipsAndUpdatesIcon />
            </div>
            <div className={is_desktop && classes.textWrapper}>
              Some customers may drop off due to this additional step. Please
              ensure that the questions are important{" "}
            </div>
          </div>
        )}
      </InteractiveTooltipWrapper>
    </>
  );
};

export default QuestionsForms;
