export const SCORING_TYPES = {
  AUTOMATIC: 1,
  MANUAL: 2,
};

export const SCORING_TYPE_OPTIONS = [
  {
    type: SCORING_TYPES.AUTOMATIC,
    label: "Automatic Scoring",
    description:
      "Assessment will have close ended questions. Submissions will be auto-graded based on your set marking scheme. Instant feedback, no manual effort.",
    icon: "assets/vectors/recorded_content/scoring_types/automatic.svg",
    color: "#BAE9E4",
    sequenceNumber: 2,
  },
  {
    type: SCORING_TYPES.MANUAL,
    label: "Manual Scoring",
    description:
      "Assessment can have open and close ended questions. After submission, you’ll be asked to evaluate each one of them.",
    icon: "assets/vectors/recorded_content/scoring_types/manual.svg",
    color: "#EDA54D",
    sequenceNumber: 2,
  },
];

export const scoringTypesFormKeys = {
  type: "type",
};
