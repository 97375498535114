import React, { useRef, useState } from "react";
import dataProvider from "../../../data/dataProvider";
import api from "../../../data/APIs";
import constants from "../../../constants/constants";
import {
  composeValidators,
  isInteger,
  is_empty,
  checkRequiredPhoneNumberValid,
  validateURL,
} from "../../../utils/validations";
import { required, minLength } from "../../../common/validate";
import { Field, useForm, useFormState } from "react-final-form";
import { ExlyInput, ExlyDropdown, ExlyPhoneInput } from "common/form";
import { Modal, Checkbox } from "@my-scoot/component-library-legacy";
import useStyles from "schedule-v2/schedule-v2.module";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { getUserChecks } from "utils/AuthUtil";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import {
  getLocationOptions,
  phone_field_names_based_on_address_types,
} from "./data";
import { useLocation } from "react-router";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { TipsAndUpdates } from "common/Components/TipsAndUpdates/TipsAndUpdates";
import SessionStorageConstants from "constants/SessionStorage.constants";
import { zoom_meeting_type } from "schedule-v2/constants";
import { setSessionStorageItem } from "utils/getterAndSetters";
import { appendUrlParams } from "utils/urlUtils";
import { ZOOM_ENTERPRISE_USER, schedule_types_ids } from "constants/schedule";
import ShareRecording from "../ShareRecording/ShareRecording";
import { ExlyNote } from "common/Components/ExlyNote/ExlyNote";
import ExlyCheckbox from "common/form/ExlyCheckbox";
import styles from "./LocationSelector.module.css";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "utils/Utils";
import { BetaTag } from "features/Common/modules/BetaTag/BetaTag";
import ZoomIsvDirectJoinToggle from "./modules/ZoomIsvDirectJoinToggle/ZoomIsvDirectJoinToggle";
import { logError } from "utils/error";

export default function LocationSelector({ dropdownProps }) {
  const location = useLocation();
  const is_desktop = useDesktopMediaQuery();
  const classes = useStyles({ is_desktop });

  const [prevAddressType, setPrevAddressType] = React.useState(null);
  const [showLocationChangeModal, setShowLocationChangeModal] =
    React.useState(null);
  const [initiated, setInitiated] = React.useState(false);
  const [zoomWebinarDetailsCache, setZoomWebinarDetailsCache] = React.useState(
    {}
  );
  const [isZoomDelink, setIsZoomDelink] = useState(false);

  const formState = useFormState();
  const form = useForm();
  const {
    address_type,
    is_auto_recordable,
    type,
    is_record_toggle_visible,
    venue_details: venueDetails,
    booking_exists: bookingExists,
    publish_date,
    recording_accessibility,
    is_access_control_editable,
    has_separate_webinar_links: hasSeparateWebinarLinks,
    is_old_webinar_listing: isOldWebinarListing,
  } = formState.values;
  const isWebinarOffering = type === schedule_types_ids.webinar;
  const joiningLink = venueDetails?.joining_link;

  const [current_address_type, set_current_address_type] =
    React.useState(address_type);
  const [zoomUserType, setZoomUserType] = React.useState(
    is_auto_recordable ? ZOOM_ENTERPRISE_USER : null
  );
  const [is_share_toggle_visible, set_is_share_toggle_visible] =
    React.useState(false);

  const [liveFeatures, setLiveFeatures] = React.useState({});

  const showZoomIsvDirectLinkToggle =
    current_address_type == constants.address_type_enum.exly_connect_by_zoom;

  const showLocationSelectorMoreOptions =
    is_share_toggle_visible || showZoomIsvDirectLinkToggle;
  React.useEffect(() => {
    (async () => {
      try {
        const check_live_profileFeature = await getUserChecks();
        if (!is_empty(check_live_profileFeature)) {
          setLiveFeatures(check_live_profileFeature);
        }
      } catch (err) {
        logError({
          err,
          occuredAt:
            "/home/unthinkable-lap/Desktop/exly creator tool/exly-creator-tool/src/schedule-v2/components/LocationSelector/index.js",
          when: "calling getUserCheck",
        });
      }
    })();
  }, []);

  React.useEffect(() => {
    if (isInteger(address_type) && current_address_type === undefined) {
      set_current_address_type(address_type);
      setPrevAddressType(address_type);
    }
    let show_share_toggle = false;
    if (
      is_record_toggle_visible && //key from backend whether can show toggle or not
      (current_address_type === constants.address_type_enum.zoom ||
        current_address_type === constants.address_type_enum.zoom_webinar) && // selected location is of zoom type
      (zoomUserType == ZOOM_ENTERPRISE_USER || // if logged in zoom and a enterprise user
        publish_date) // if already published
    ) {
      show_share_toggle = true;
    }
    if (is_auto_recordable) {
      // if the switch is already on
      show_share_toggle = true;
    }

    // will be ZOOM_ENTERPRISE_USER if can select exly connect
    if (
      current_address_type === constants.address_type_enum.exly_connect_by_zoom
    ) {
      show_share_toggle = true;
    }
    set_is_share_toggle_visible(show_share_toggle);
  }, [current_address_type, zoomUserType, address_type]);

  const { notify } = useNotifications();

  const generateGoogleMeetLink = async (value) => {
    try {
      const response = await dataProvider.custom_request(
        api.check_google_integration,
        "GET"
      );

      if (response.data.is_valid === false) {
        const redirect = await dataProvider.custom_request(
          api.calendar_initiate,
          apiMethods.POST,
          { return_url: `${window.location.href}&source=${constants.meet}` }
        );

        if (redirect?.data?.redirect_url) {
          setSessionStorageItem(
            SessionStorageConstants.CALENDAR_PERMISSION,
            "true"
          );
          window.location.href = redirect.data.redirect_url;
        }
      } else {
        if (!venueDetails?.meet_link) {
          const { data } = await dataProvider.custom_request(
            api.generate_meet_link,
            apiMethods.POST,
            {}
          );
          changeAddressType(value);
          form.change("venue_details.joining_link", data.meet_link);
        }
      }
    } catch (error) {
      logError({
        error,
        occuredAt:
          "/home/unthinkable-lap/Desktop/exly creator tool/exly-creator-tool/src/schedule-v2/components/LocationSelector/index.js",
        when: "while checking google integration.",
      });
    }
  };

  const skipZoomLinkGeneration = isWebinarOffering && hasSeparateWebinarLinks;
  // this ref is used to prevent setting zoom link in form if skipZoomLinkGeneration is set to false while zoom link generation from api call
  // this will provide latest value of skipZoomLinkGeneration during api call
  const skipZoomLinkGenerationRef = useRef(skipZoomLinkGeneration);
  skipZoomLinkGenerationRef.current = skipZoomLinkGeneration;

  const generateZoomLink = async (value, skipZoomLinkGeneration) => {
    try {
      const { data } = await dataProvider.custom_request(
        api.check_zoom_integration,
        apiMethods.POST,
        {
          return_url: `${window.location.href}&source=${constants.zoom}${
            !formState.values?.is_draft && !is_empty(formState.values?.uuid)
              ? `&zoom_published=true`
              : ""
          }`,
        }
      );
      setZoomUserType(data?.user_type);

      if (!is_empty(data?.redirect_url)) {
        setSessionStorageItem(
          SessionStorageConstants.CALENDAR_PERMISSION,
          "true"
        );
        window.location.href = data?.redirect_url;
      } else {
        if (!formState.values?.is_draft && !is_empty(formState.values?.uuid)) {
          if (skipZoomLinkGeneration) return;

          const zoom_link = await dataProvider.custom_request(
            api.generate_zoom_link,
            apiMethods.POST,
            {
              listing_uuid: formState.values?.uuid,
              meeting_type: zoom_meeting_type.zoom,
            }
          );
          if (isRequestSuccessful(zoom_link.status)) {
            form.change(
              "venue_details.joining_link",
              zoom_link.data.zoom_meeting_url
            );
            form.change(
              "venue_details.zoom_meeting_id",
              zoom_link?.data?.zoom_meeting_id
            );
          }
        } else {
          notify(
            `${
              formState.values?.address_type ===
              constants.address_type_enum.zoom_webinar
                ? "Link will be generated when session is booked"
                : "Link will be generated when a guest books the slot."
            } `,
            notification_color_keys.info
          );
        }
        changeAddressType(value);
      }
    } catch (error) {
      logError({
        error,
        occuredAt:
          "/home/unthinkable-lap/Desktop/exly creator tool/exly-creator-tool/src/schedule-v2/components/LocationSelector/index.js",
        when: "generating zoom link",
      });
      notify(
        "An error occurred while generating the Zoom link.",
        notification_color_keys.error
      );
    }
  };

  const handleSetZoomWebinarCache = (payload) => {
    setZoomWebinarDetailsCache((prevCache) => ({ ...prevCache, ...payload }));
  };

  // Function to generate zoom webinar link
  const generateZoomWebinarLink = async (value, skipZoomLinkGeneration) => {
    try {
      const isZoomPublished =
        !formState.values?.is_draft && !is_empty(formState.values?.uuid);
      const returnUrlPayload = {
        source: constants.zoom_webinar,
      };
      if (isZoomPublished) returnUrlPayload.zoom_published = isZoomPublished;

      const returnUrl = appendUrlParams(window.location.href, returnUrlPayload);

      let data;

      if (is_empty(zoomWebinarDetailsCache?.user_type)) {
        const { data: responseData } = await dataProvider.custom_request(
          api.check_zoom_integration,
          apiMethods.POST,
          {
            return_url: returnUrl,
          }
        );
        data = responseData;
        const { user_type: userType } = data ?? {};
        setZoomUserType(userType);
        handleSetZoomWebinarCache({ userType });
      } else {
        setZoomUserType(zoomWebinarDetailsCache?.userType);
      }

      if (!is_empty(data?.redirect_url)) {
        setSessionStorageItem(
          SessionStorageConstants.CALENDAR_PERMISSION,
          "true"
        );
        window.location.href = data?.redirect_url;
      } else if (!skipZoomLinkGeneration) {
        if (is_empty(zoomWebinarDetailsCache?.zoomMeetingUrl)) {
          const zoomLink = await dataProvider.custom_request(
            api.generate_zoom_link,
            apiMethods.POST,
            {
              listing_uuid: formState.values?.uuid,
              meeting_type: zoom_meeting_type.zoom_webinar,
            }
          );
          if (isRequestSuccessful(zoomLink.status)) {
            const zoomMeetingUrl = zoomLink.data.zoom_meeting_url;
            const zommMeetingId = zoomLink?.data?.zoom_meeting_id;
            if (!skipZoomLinkGenerationRef.current) {
              form.change("venue_details.joining_link", zoomMeetingUrl);
              form.change("venue_details.zoom_meeting_id", zommMeetingId);
            }
            handleSetZoomWebinarCache({ zoomMeetingUrl, zommMeetingId });
          }
        } else {
          form.change(
            "venue_details.joining_link",
            zoomWebinarDetailsCache?.zoomMeetingUrl
          );
          form.change(
            "venue_details.zoom_meeting_id",
            zoomWebinarDetailsCache?.zommMeetingId
          );
        }

        changeAddressType(value);
      }
    } catch (error) {
      logError({
        error,
        occuredAt:
          "/home/unthinkable-lap/Desktop/exly creator tool/exly-creator-tool/src/schedule-v2/components/LocationSelector/index.js",
        when: "calling generateZoomWebinarLink",
      });
    }
  };

  const changeAddressType = (value) => {
    if (value !== address_type) setPrevAddressType(address_type);
    form.change("address_type", value);
    set_current_address_type(value);
  };

  React.useEffect(() => {
    if (initiated) return;

    if (formState.values?.title && initiated && joiningLink) {
      return;
    }

    const urlParams = new URLSearchParams(location.search);
    const sourceParam = urlParams.get("source") || "";

    if (sourceParam === constants.meet) {
      setInitiated(true);
      form.change("address_type", constants.address_type_enum.meet);
      generateGoogleMeetLink(constants.address_type_enum.meet);
      return;
    }

    if (sourceParam === constants.zoom) {
      generateZoomLink(constants.address_type_enum.zoom);
      setInitiated(true);
    }
  }, [formState.values]);

  const fetchZoomUserType = async () => {
    const { data } = await dataProvider.custom_request(
      api.check_zoom_integration,
      apiMethods.POST,
      {
        return_url: `${window.location.href}&source=${constants.zoom}${
          !formState.values?.is_draft && !is_empty(formState.values?.uuid)
            ? `&zoom_published=true`
            : ""
        }`,
      }
    );
    setIsZoomDelink(!data?.isZoomIntegrated);
    setZoomUserType(data?.user_type);
  };

  React.useEffect(() => {
    if (formState.values?.address_type === constants.address_type_enum.zoom) {
      fetchZoomUserType();
    }
  }, [formState.values.address_type]);

  let extraParams = {};

  if (type === constants.scheduleTypesId.no_schedule) {
    extraParams = {
      helperText:
        "Customers who make the payment can use this to reach out to you for any queries.",
    };
  }

  const options = getLocationOptions({
    liveFeatures,
    scheduleType: type,
  }).filter((i) => !i.hide);

  const creatorZoomAccHasProAccess =
    formState.values.is_zoom_attendance_eligible;
  const showAttendanceTrackingCheckBox =
    (address_type === constants.address_type_enum.zoom ||
      address_type === constants.address_type_enum.zoom_webinar) &&
    creatorZoomAccHasProAccess;

  const zoomAttendanceTrackingEnabled =
    !!formState.values.is_zoom_attendance_enabled;
  function toggleAttendanceTracking() {
    if (!showAttendanceTrackingCheckBox) return;
    const newVal = !zoomAttendanceTrackingEnabled;
    if (newVal && formState.values.booking_exists) {
      notify(
        "Attendance will be recorded only for sessions conducted from now on.",
        "info"
      );
    }
    form.change("is_zoom_attendance_enabled", newVal);
  }

  let phoneFieldName = "";
  phoneFieldName = phone_field_names_based_on_address_types[address_type] || "";

  const isAddressTypeZoom =
    address_type === constants.address_type_enum.zoom ||
    address_type === constants.address_type_enum.zoom_webinar;

  const showMeetingLink =
    address_type === constants.address_type_enum.meet || isAddressTypeZoom;

  const showIndividualZoomLinksFeatureEnabled =
    isWebinarOffering && !isOldWebinarListing && isAddressTypeZoom;
  const showIndividualZoomLinksNote =
    isAddressTypeZoom && isWebinarOffering && skipZoomLinkGeneration;

  const handleUniqueMeetingLinksChange = (isEnabled) => {
    if (isEnabled) {
      form.change("venue_details", {});
    } else {
      if (address_type === constants.address_type_enum.zoom) {
        generateZoomLink(address_type, isEnabled);
      } else if (address_type === constants.address_type_enum.zoom_webinar) {
        generateZoomWebinarLink(address_type, isEnabled);
      }
    }
  };

  return (
    <>
      <Field
        placeholder="Please Choose one"
        options={options}
        name="address_type"
        value={address_type}
        fullWidth
        component={ExlyDropdown}
        size="small"
        helperTextClassName={classes.dropdownHelperText}
        onChange={(value) => {
          form.change("venue_details", {});
          if (
            value !== constants.address_type_enum.zoom &&
            value !== constants.address_type_enum.zoom_webinar &&
            is_auto_recordable &&
            (current_address_type === constants.address_type_enum.zoom ||
              current_address_type === constants.address_type_enum.zoom_webinar)
          ) {
            setShowLocationChangeModal(value);
            return;
          }

          if (value === constants.address_type_enum.meet && !joiningLink) {
            generateGoogleMeetLink(value);
          } else if (value === constants.address_type_enum.zoom) {
            generateZoomLink(value, skipZoomLinkGeneration);
          } else if (value === constants.address_type_enum.zoom_webinar) {
            generateZoomWebinarLink(value, skipZoomLinkGeneration);
          } else if (
            value === constants.address_type_enum.exly_connect_by_zoom
          ) {
            changeAddressType(value);
          } else {
            form.change("is_auto_recordable", false);
            changeAddressType(value);
          }
        }}
        {...extraParams}
        {...dropdownProps}
        validate={composeValidators(required)}
        disabled={!is_empty(publish_date) && isWebinarOffering} //if the webinar is published we can't change the location
      />

      {showMeetingLink && (
        <small className="text-muted">
          {isAddressTypeZoom && joiningLink ? "Universal meeting link: " : ""}
          {joiningLink}
        </small>
      )}

      {address_type === constants.address_type_enum.exly_connect_by_zoom && (
        <small className="font-weight-bold text-warning d-flex mt-2">
          <InfoIcon fontSize="small" />
          <div className="ml-1">
            Exly Connect meeting links can be accessed from its section under
            Workflow Tools
          </div>
        </small>
      )}

      {showIndividualZoomLinksFeatureEnabled ? (
        <div className="mt-3 mb-2">
          <Field
            id="has_separate_webinar_links"
            name="has_separate_webinar_links"
            component={ExlyCheckbox}
            label="Generate unique joining links for each participant"
            size="large"
            labelClassName={styles.label}
            onChange={handleUniqueMeetingLinksChange}
            disabled={bookingExists}
          />
        </div>
      ) : null}

      {showIndividualZoomLinksNote ? (
        <ExlyNote
          label={
            <span>
              <strong>Note:</strong> When enabled, this option will create a
              unique joining link for each participant, enhancing security. Use
              this for preventing unauthorized access.
            </span>
          }
        />
      ) : null}

      {showAttendanceTrackingCheckBox && (
        <>
          <div className="d-flex mt-3 mb-2">
            <Checkbox
              size="large"
              disableRipple="false"
              wrapperClassName="mt-1"
              checked={zoomAttendanceTrackingEnabled}
              onClick={toggleAttendanceTracking}
            />
            <div className="pl-2">
              <span className="pointer" onClick={toggleAttendanceTracking}>
                Enable Attendance Tracking <BetaTag />
              </span>
            </div>
          </div>
          {zoomAttendanceTrackingEnabled ? (
            <TipsAndUpdates
              iconSize="25px"
              tip={
                <div className="text-muted small">
                  Attendance will be marked of participants if they sign in
                  using same email used while booking
                </div>
              }
            />
          ) : null}
        </>
      )}

      {address_type === constants.address_type_enum.joining_link && (
        <div className="mt-3">
          <Field
            placeholder="Online Meeting URL"
            size="small"
            name="venue_details.joining_link"
            style={{ width: "100%" }}
            label="Enter online meeting URL*"
            validate={composeValidators(required, validateURL)}
            component={ExlyInput}
          />

          <div className="py-2"></div>

          <Field
            placeholder="Password"
            size="small"
            name="venue_details.joining_password"
            style={{ width: "100%" }}
            label="Enter online meeting password"
            component={ExlyInput}
          />
        </div>
      )}

      {address_type === constants.address_type_enum.personal_address && (
        <div className="mt-3">
          <Field
            placeholder="Address"
            size="small"
            label="Enter your personal address*"
            name="venue_details.address"
            style={{ width: "100%" }}
            component={ExlyInput}
            validate={composeValidators(
              required,
              minLength(3, "Address too short")
            )}
          />
        </div>
      )}

      {[
        constants.address_type_enum.phone,
        constants.address_type_enum.whatsapp,
      ].includes(address_type) && (
        <div className="mt-3">
          <Field
            placeholder="Phone Number"
            size="small"
            label="Enter your phone number*"
            name={phoneFieldName}
            component={ExlyPhoneInput}
            required
            validate={checkRequiredPhoneNumberValid}
          />
        </div>
      )}

      {showLocationSelectorMoreOptions && (
        <div className="mt-3 border-top">
          {/* 
            show switch for allowing only authenticated user to join,
            user is a premium zoom user. 
          */}
          {showZoomIsvDirectLinkToggle && <ZoomIsvDirectJoinToggle />}
          {/* 
            show switch for recording zoom sessions,
            only show if listing has schedule and user is a premium
            zoom user. 
          */}
          {is_share_toggle_visible && (
            <ShareRecording
              isAutoRecordable={is_auto_recordable}
              recordingAccessControl={recording_accessibility}
              isAccessControlEditable={is_access_control_editable}
              addressType={address_type}
              form={form}
              type={type}
            />
          )}
        </div>
      )}
      {isZoomDelink && (
        <div className={styles.disclamer}>
          <InfoIcon
            className={styles.infoIcon}
            onClick={(e) => e.stopPropagation()}
          />
          <div className={styles.textValue}>
            <b>Note: </b>Your Zoom account has been delinked, so we no longer
            have access to your recordings. To retrieve recordings, please
            access your Zoom account directly or link a new zoom account.
          </div>
        </div>
      )}

      {isInteger(showLocationChangeModal) && (
        <Modal
          open={isInteger(showLocationChangeModal)}
          title="Confirmation"
          primaryBtnText="Confirm"
          secondaryBtnText="Cancel"
          onPrimaryBtnClick={() => {
            changeAddressType(showLocationChangeModal);
            form.change("is_auto_recordable", false);
            setShowLocationChangeModal(null);
          }}
          onSecondaryBtnClick={() => {
            setShowLocationChangeModal(null);
            changeAddressType(constants.address_type_enum.zoom);
          }}
        >
          <div className="p-3">
            Are you sure you want to change the meeting location to{" "}
            <b>{constants.address_type[showLocationChangeModal]}</b>?
            <br />
            <br />
            <b>Note:</b> You will no longer be able to share your recordings.
          </div>
        </Modal>
      )}
      <Modal
        open={
          prevAddressType !==
            constants.address_type_enum.exly_connect_by_zoom &&
          address_type === constants.address_type_enum.exly_connect_by_zoom &&
          bookingExists
        }
        showCross={false}
        title="Confirmation"
        primaryBtnText="Proceed"
        onPrimaryBtnClick={() => {
          setPrevAddressType(constants.address_type_enum.exly_connect_by_zoom);
        }}
        secondaryBtnText="Cancel"
        onSecondaryBtnClick={() => {
          changeAddressType(prevAddressType);
        }}
      >
        <div className="p-3">
          Warning! This will change the link that&apos;s being used by your
          users and may lead to confusion. Confirm to proceed with this.
        </div>
      </Modal>
    </>
  );
}
