import { is_empty } from "utils/validations";
import throttle from "lodash/throttle";
import { useEffect, useMemo } from "react";
import { variantFormKeys } from "./Components/MerchandisePriceAndStockDetails/components/Variant/data";
import { variantsFormKeys } from "./Components/MerchandisePriceAndStockDetails/data";
import { variantDetailsFormKeys } from "./Components/MerchandisePriceAndStockDetails/Modals/VariantDetailsModal/data";
import { useForm, useFormState } from "react-final-form";
import { change_states } from "constants/recordedContent";
import { checkObjectHasEqualValues } from "utils/object";

export const DEFAULT_AUTO_SAVE_DURATION = 15_000; // in ms

export const localStorageKeys = {
  FORM_STATE: "formState",
};

export const removeFormFields = (form, formFieldsEnum, shouldRemove) => {
  if (is_empty(form) || is_empty(formFieldsEnum)) return;
  Object.values(formFieldsEnum).forEach((formKey) => {
    let flag = true;
    if (shouldRemove) flag = shouldRemove(formKey);
    if (flag) form.change(formKey, undefined);
  });
};

/**
 *
 * @param {attributes} attributes
 * variant attributes
 * example : attributes = { color: ['Red', 'Blue'], sizes: ['Small', 'Medium', 'Large'] };
 * @returns possible combinations (cartesian product of all attributes)
 */
export const getCombinations = (attributes) => {
  let attrs = [];

  for (const [attr, values] of Object.entries(attributes))
    if (!is_empty(values)) attrs.push(values.map((v) => ({ [attr]: v })));

  return attrs.length > 0
    ? attrs
        .reduce((a, b) => a.flatMap((d) => b.map((e) => ({ ...d, ...e }))))
        .map((combination) => ({
          [variantDetailsFormKeys.VARIANT_MAP]: combination,
        }))
    : [];
};

// this hook will set possible variations in form state in case of merchandise offering
export const useGeneratePossibleVariations = () => {
  const form = useForm();
  const { values: formState } = useFormState();

  const variationDisplayData = useMemo(
    throttle(() => {
      const variants = formState[variantsFormKeys.VARIANTS_DISPLAY_DATA];

      if (is_empty(variants)) return [];

      const variantsData = {};

      variants.forEach((variant) => {
        if (is_empty(variant)) return;

        const {
          [variantFormKeys.TYPE]: type,
          [variantFormKeys.CUSTOM_TYPE]: customType,
          [variantFormKeys.OPTIONS]: options,
        } = variant;

        variantsData[customType || type] = options;
      });

      return variantsData;
    }, 2000),
    [formState[variantsFormKeys.VARIANTS_DISPLAY_DATA]]
  );

  const possibleVariations = useMemo(
    () => getCombinations(variationDisplayData),
    [formState[variantsFormKeys.VARIANTS_DISPLAY_DATA]]
  );

  useEffect(() => {
    form.change(variantsFormKeys.POSSIBLE_VARIATIONS, [...possibleVariations]);
  }, [possibleVariations]);

  // optionally returning generated possible variantions
  // which can also be accessed from form state by using useFormState hook
  return { possibleVariations };
};

/**
 *
 * @param {Array} possibleVariations all possible variations array
 * @param {Array} addedVariations all added variations array
 * @returns first possible variant index in added variants array containing data
 */
const getFirstVariantIndex = (possibleVariations, addedVariations) => {
  let firstVariantIndex = -1;
  possibleVariations.find(
    ({ [variantDetailsFormKeys.VARIANT_MAP]: currentVariantMap }) => {
      firstVariantIndex = addedVariations.findIndex(
        ({
          [variantDetailsFormKeys.VARIANT_MAP]: currentAddedVariantMap,
          [variantDetailsFormKeys.CHANGE_STATE]: changeState,
        }) =>
          checkObjectHasEqualValues(
            currentVariantMap,
            currentAddedVariantMap
          ) && changeState !== change_states.deleted
      );
      return firstVariantIndex !== -1;
    }
  );
  return firstVariantIndex;
};

export const getPriceCalculatorPropsForVariations = (
  possibleVariations,
  addedVariations
) => {
  if (is_empty(addedVariations) || is_empty(possibleVariations)) return {};

  // showing price price calculator card only for first variant
  const firstVariantIndex = getFirstVariantIndex(
    possibleVariations,
    addedVariations
  );

  if (firstVariantIndex === -1) return {};

  const firstVariant = addedVariations[firstVariantIndex];
  const { price_per_head, price_international } = firstVariant;

  return { price_per_head, price_international };
};
