import { api, dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { addCertificateAPI } from "./addCertificate.api";

export const fetchCertificates = async ({
  status: certificate_status,
  listingUuid,
}) => {
  const { data, status } = await dataProvider.custom_request(
    api.get_certificates,
    apiMethods.GET,
    {
      certificate_status,
      listing__uuid: listingUuid,
    }
  );
  if (status !== 200)
    throw new Error(`Error creating questionnaire: ${data} ${status}`);

  const { certificates } = data;
  return certificates;
};

export const fetchExams = async ({
  listingUuid,
  abortSignal,
  isReportPublished,
}) => {
  const { data, status } = await dataProvider.custom_request(
    addCertificateAPI.getExams,
    apiMethods.GET,
    {
      listing_uid: listingUuid,
      show_result: isReportPublished,
    },
    false,
    abortSignal
  );
  if (status !== 200)
    throw new Error(`Error creating questionnaire: ${data} ${status}`);

  const { questionairres = [] } = data;
  return questionairres;
};
