import { updateQueryStringParameter } from "common/util";
import { offering_availability_statuses } from "features/Listings/Listings.constants";
import SessionStorageConstants from "constants/SessionStorage.constants";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { processConflictsInScheduleManagementApiResponse } from "ui/pages/schedule/YourSchedule/data";
import { appendUrlParams } from "utils/urlUtils";
import { is_empty } from "utils/validations";
import planConstants from "./Plans/Constants";
import { constants as listingConstants } from "ui/pages/schedule/ScheduleList/constants";

export const processConflictsInCheckListigConflictsApiResponse = ({
  apiResponse,
}) =>
  processConflictsInScheduleManagementApiResponse(apiResponse?.data?.conflicts);

export const checkConflictsExist = (conflicts) =>
  conflicts?.overlapping_sessions?.length ||
  conflicts?.rescheduled_sessions?.length ||
  conflicts?.unavailabilities?.length;

export const processConflictsOnUnavailabilityConflictResolution = ({
  uuid,
  conflicts,
}) => {
  const unavailabilities = conflicts.data.unavailabilities.filter(
    (i) => i.uuid !== uuid
  );
  return {
    ...conflicts,
    data: { ...conflicts.data, unavailabilities },
    show: !!unavailabilities.length,
  };
};

export const COMMON_SKU_TRIGGERS = {
  pricing_next: "On next in pricing page",
};

export const DRM_MEDIA_TYPE = 2;

// common accross all sku's, this function handles the in-between steps navigation on sku creation flow
export const handleSkuPrevious = ({ page, routeComingFrom, prevActiveTab }) => {
  if (page === 0) {
    window.location.href = !is_empty(routeComingFrom)
      ? `/#/${routeComingFrom}` +
        appendUrlParams("", {
          published: false,
        })
      : `/#/${app_route_ids[app_route_keys.schedule_list]}` +
        appendUrlParams("", {
          [planConstants.PREV_ACTIVE_TAB.key]: prevActiveTab,
        });
    window.sessionStorage.setItem(
      SessionStorageConstants.ROUTE_COMING_FROM,
      ""
    );
  } else {
    window.location.href = updateQueryStringParameter(
      window.location.href,
      "page",
      page - 1
    );
  }
};

export const getPreviousTabFromStatus = (status) => {
  switch (status) {
    case offering_availability_statuses.draft:
      return listingConstants.tabLabel.draft;

    case offering_availability_statuses.sold_out:
      return listingConstants.tabLabel.sold;

    case offering_availability_statuses.expired:
      return listingConstants.tabLabel.past;

    default:
      return listingConstants.tabLabel.live;
  }
};
