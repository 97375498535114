import TickIcon from "assets/vectors/recorded-content/check_small.svg";
import RadioCheckedIcon from "assets/vectors/recorded-content/radio_button_checked.svg";
import ShortTextIcon from "assets/vectors/recorded-content/mediaTypes/short_text.svg";
import StarRatingIcon from "assets/vectors/recorded-content/mediaTypes/star_rating.svg";
import NumberScaleRatingIcon from "assets/vectors/recorded-content/mediaTypes/number_scale_rating.svg";
import DateIcon from "assets/vectors/recorded-content/mediaTypes/calendar.svg";
import FileUploadIcon from "assets/vectors/recorded-content/mediaTypes/cloud_upload.svg";

import { QUESTION_TYPES } from "schedule-v2/components/recorded-content/recordedContent.data";

export const EXAM_PAGES = {
  SELECT_TYPE: 1,
  ADD_DETAILS: 2,
  SELECT_ANSWER: 3,
};

export const FORM_PAGES = {
  SELECT_TYPE: 1,
  ADD_DETAILS: 2,
};

export const TOTAL_PAGES = {
  options_type: 3,
  regular: 2,
};

export const AUTOMATIC_EXAM_TYPE_OPTIONS = {
  [QUESTION_TYPES.SINGLE_SELECT]: {
    type: QUESTION_TYPES.SINGLE_SELECT,
    label: "Single correct",
    subLabel: "Single select",
    description:
      "Options can be multiple but correct answer will only be one of them.",
    icon: RadioCheckedIcon,
    color: "#F9A7D3",
    sequenceNumber: 2,
  },
  [QUESTION_TYPES.MULTI_SELECT]: {
    type: QUESTION_TYPES.MULTI_SELECT,
    label: "Multiple correct",
    subLabel: "Multiple select",
    description:
      "Options can be multiple and correct answer(s) can be one, some or all of them.",
    icon: TickIcon,
    color: "#F6DB79",
    sequenceNumber: 3,
  },
};

export const MANUAL_SCORING_EXAM_TYPE_OPTIONS = {
  ...AUTOMATIC_EXAM_TYPE_OPTIONS,
  [QUESTION_TYPES.TEXT_QUESTION]: {
    type: QUESTION_TYPES.TEXT_QUESTION,
    label: "Short text",
    subLabel: "Short text answer",
    description:
      "This is for open ended questions. Choose in cases where you require descriptive answers.",
    icon: ShortTextIcon,
    color: "#A6CEF9",
    sequenceNumber: 4,
  },
  [QUESTION_TYPES.FILE]: {
    type: QUESTION_TYPES.FILE,
    label: "File upload",
    description:
      "Choose this in cases where you require the student to upload a file. Only image, video and pdf are allowed.",
    icon: FileUploadIcon,
    color: "#CCE9BA",
    sequenceNumber: 5,
  },
};

export const FORM_TYPE_OPTIONS = {
  [QUESTION_TYPES.TEXT_QUESTION]: {
    type: QUESTION_TYPES.TEXT_QUESTION,
    label: "Short text",
    subLabel: "Short text answer",
    description:
      "This is for open ended questions. Choose in cases where you require descriptive answers.",
    icon: ShortTextIcon,
    color: "#A6CEF9",
    sequenceNumber: 1,
  },
  [QUESTION_TYPES.SINGLE_SELECT]:
    AUTOMATIC_EXAM_TYPE_OPTIONS[QUESTION_TYPES.SINGLE_SELECT],
  [QUESTION_TYPES.MULTI_SELECT]:
    AUTOMATIC_EXAM_TYPE_OPTIONS[QUESTION_TYPES.MULTI_SELECT],
  [QUESTION_TYPES.STAR_RATING]: {
    type: QUESTION_TYPES.STAR_RATING,
    label: "Star rating",
    subLabel: "Star rating (1-5)",
    description:
      "Students will be presented with a 5-star rating scale. Only possible star ratings: 1, 2, 3, 4, 5 star(s).",
    icon: StarRatingIcon,
    color: "#EDA54D",
    sequenceNumber: 4,
  },
  [QUESTION_TYPES.FIVE_NUMBER_RATING]: {
    type: QUESTION_TYPES.FIVE_NUMBER_RATING,
    label: "Number scale rating",
    subLabel: "Number scale rating (1-5)",
    description:
      "You can choose the number range, as well as choose what the numbers on each end of the scale signify.",
    icon: NumberScaleRatingIcon,
    color: "#BAE9E4",
    sequenceNumber: 5,
  },
  [QUESTION_TYPES.TEN_NUMBER_RATING]: {
    type: QUESTION_TYPES.FIVE_NUMBER_RATING,
    label: "Number scale rating",
    subLabel: "Number scale rating (1-10)",
    description:
      "You can choose the number range, as well as choose what the numbers on each end of the scale signify.",
    icon: NumberScaleRatingIcon,
    color: "#BAE9E4",
    sequenceNumber: 6,
    hide: true,
  },
  [QUESTION_TYPES.CALENDAR]: {
    type: QUESTION_TYPES.CALENDAR,
    label: "Date",
    description:
      "This will present a calendar. Choose in cases where you require a date type entry.",
    icon: DateIcon,
    color: "#EED7FC",
    sequenceNumber: 7,
  },
  [QUESTION_TYPES.FILE]: {
    type: QUESTION_TYPES.FILE,
    label: "File upload",
    description:
      "Choose this in cases where you require the student to upload a file. Only image, video and pdf are allowed.",
    icon: FileUploadIcon,
    color: "#CCE9BA",
    sequenceNumber: 8,
  },
};

export const questionsModalFormkeys = {
  QUESTION_TYPE: "type",
  QUESTION: "question",
  OPTIONS: "options",
  ANSWER: "answer",
};

export const DUMMY_FORM_KEYS = {
  SELECT_ANSWER: "select_answer",
};
