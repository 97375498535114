// TODO: create separate folder for each component

import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useFormState } from "react-final-form";

import classnames from "classnames";

import {
  Button,
  DatePicker,
  RadioGroup,
  Textarea,
} from "@my-scoot/component-library-legacy";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Button as MuiButton,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/AddCircleOutlined";
import TickIcon from "@material-ui/icons/CheckRounded";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import EditIcon from "@material-ui/icons/EditOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/MoreVert";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { Rating } from "@material-ui/lab";
import DeleteIconLined from "assets/images/delete_lined.svg";
import UploadIcon from "assets/vectors/recorded-content/upload.svg";

import CheckboxGroup from "common/Components/CheckboxGroup/CheckboxGroup";
import ExlyPopover from "common/Components/ExlyPopover";

import { examCreationFormKeys } from "schedule-v2/components/recorded-content/ExamCreation/examCreation.data";
import { FILE_TYPES } from "schedule-v2/components/recorded-content/ExamCreation/modals/QuestionsModal/pages/QuestionDetails/QuestionDetails.data";
import { FORM_TYPE_OPTIONS } from "schedule-v2/components/recorded-content/ExamCreation/modals/QuestionsModal/questionsModal.data";
import { SCORING_TYPES } from "schedule-v2/components/recorded-content/ExamCreation/modals/ScoringType/scoringType.data";
import {
  QUESTIONNAIRE_FORM_KEYS,
  QUESTIONNAIRE_TYPES,
  QUESTION_CHANGE_STATES,
  QUESTION_KEYS,
  QUESTION_OPTION_KEYS,
  QUESTION_TYPES,
} from "schedule-v2/components/recorded-content/recordedContent.data";
import { ellipsize, pluralise } from "utils/Utils";
import { questionnaireConstants } from "../../questionnaire.data";
import { getRecordedContentFileName } from "./utils";

import { is_empty } from "utils/validations";
import styles from "./helperComponents.module.css";

const QuestionSummary = ({
  isDesktop,
  children,
  expanded,
  onEdit,
  onDelete,
  onExpand,
}) => {
  const formActions = [
    {
      id: 1,
      label: "Edit question",
      icon: <EditIcon className={styles.question__editIcon} />,
      onClick: onEdit,
    },
    {
      id: 2,
      label: "Delete question",
      icon: (
        <img src={DeleteIconLined} className={styles.question__deleteIcon} />
      ),
      onClick: onDelete,
    },
  ];

  const EditAnchorComponent = ({ openPopover }) => (
    <IconButton
      onClick={(e) => {
        e.stopPropagation();
        openPopover(e);
      }}
    >
      <MenuIcon className={styles.question__summary_expandIcon} />
    </IconButton>
  );

  const PopoverComponent = ({ closePopover }) => {
    return (
      <div>
        {formActions.map(({ id, label, icon, onClick }) => (
          <div key={id}>
            <MuiButton
              className={styles.actionCta}
              fullWidth
              startIcon={icon}
              onClick={() => {
                closePopover();
                onClick();
              }}
            >
              {label}
            </MuiButton>
          </div>
        ))}
      </div>
    );
  };

  return (
    <AccordionSummary
      classes={{
        root: styles.question__summary,
        content: styles.question__content,
      }}
    >
      <div className={styles.question__content__container}>
        <div>{children}</div>
        <div
          className={styles.question__ctas}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.question__actionCtas}>
            {isDesktop && onEdit && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  if (onEdit) onEdit(e);
                }}
              >
                <EditIcon className={styles.question__editIcon} />
              </IconButton>
            )}
            {isDesktop && onDelete && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  if (onDelete) onDelete(e);
                }}
              >
                <img
                  src={DeleteIconLined}
                  className={styles.question__deleteIcon}
                />
              </IconButton>
            )}

            {!isDesktop && (
              <ExlyPopover
                AnchorComponent={EditAnchorComponent}
                PopoverComponent={PopoverComponent}
                forceDesktopDesign={false}
                popoverProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                }}
              />
            )}
          </div>
          <IconButton onClick={onExpand}>
            <ExpandMoreIcon
              className={classnames(
                styles.question__summary_expandIcon,
                expanded && styles.question__summary__expandedIcon
              )}
            />
          </IconButton>
        </div>
      </div>
    </AccordionSummary>
  );
};

export const SingleOrMultipleSelectContent = ({ options, isAutoScoring }) => {
  return (
    <ul className={styles.questionContent}>
      {options?.map(
        ({
          [QUESTION_OPTION_KEYS.option]: option,
          [QUESTION_OPTION_KEYS.isCorrect]: isCorrect,
          [QUESTION_OPTION_KEYS.sequenceNo]: sequenceNo,
        }) => (
          <li key={sequenceNo}>
            <div>
              {option}
              {isCorrect && isAutoScoring && (
                <span>
                  correct <TickIcon />
                </span>
              )}
            </div>
          </li>
        )
      )}
    </ul>
  );
};

export const StarRatingFormQuestion = ({ value, isDisabled }) => {
  return (
    <div className={styles.starRating}>
      <Rating
        value={value}
        precision={0.5}
        disabled={isDisabled}
        emptyIcon={<StarBorderIcon />}
        readOnly
      />
    </div>
  );
};

export const NumberRating = ({ type, value }) => {
  const scale = type === QUESTION_TYPES.FIVE_NUMBER_RATING ? 5 : 10;
  return (
    <>
      <table className={styles.numberRating}>
        <tr>
          {Array(scale)
            .fill(undefined)
            .map((item, index) => (
              <td
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={classnames(
                  value === index + 1 && styles.selectedRating
                )}
              >
                {index + 1}
              </td>
            ))}
        </tr>
      </table>
    </>
  );
};

export const ImageResonse = ({ url }) => {
  if (is_empty(url)) return null;

  return (
    <div className={styles.imageResonseRoot}>
      <img className={styles.responseImage} src={url} alt="image answer" />
    </div>
  );
};

export const FormQuestion = ({
  question,
  isResponse,
  isDisabled = true,
  isExam,
  isAutoScoring = true,
}) => {
  const {
    [QUESTION_KEYS.questionType]: type,
    [QUESTION_KEYS.options]: options,
    [QUESTION_KEYS.answer]: answer,
    [QUESTION_KEYS.metadata]: metadata,
  } = question;
  const { [QUESTION_KEYS.metadataKeys.uploadType]: uploadType } =
    metadata || {};

  const optionsToRender = options?.map((option) => ({
    id: option.option,
    label: option.option,
    value: option.option,
    checked: isResponse
      ? option[QUESTION_OPTION_KEYS.isSelected]
      : option[QUESTION_OPTION_KEYS.isCorrect],
    error:
      isAutoScoring &&
      isExam &&
      option[QUESTION_OPTION_KEYS.isSelected] &&
      !option[QUESTION_OPTION_KEYS.isCorrect],
    color: isExam && isAutoScoring ? "#00A570" : "",
  }));

  switch (type) {
    case QUESTION_TYPES.TEXT_QUESTION:
      return (
        <Textarea
          minHeight="74px"
          minWidth="100%"
          customClasses={{
            wrapper: styles.datePicker,
            textarea: isDisabled && styles.textDisable,
          }}
          disabled={isDisabled}
          value={answer}
        />
      );
    case QUESTION_TYPES.STAR_RATING:
      return <StarRatingFormQuestion value={Number(answer)} isDisabled />;
    case QUESTION_TYPES.SINGLE_SELECT: {
      const radioGroupValue = optionsToRender?.find((option) => option.checked);
      return (
        <RadioGroup
          disabled={isDisabled}
          selected={radioGroupValue}
          options={optionsToRender}
          formControlClassName={styles.radioGroup}
          radioProps={{
            color: isExam && isAutoScoring ? "#00A570" : "",
          }}
        />
      );
    }
    case QUESTION_TYPES.MULTI_SELECT: {
      const checkboxGroupValue = optionsToRender
        ?.filter((option) => option.checked)
        .map((option) => option.id);
      return (
        <CheckboxGroup
          className={styles.datePicker}
          options={optionsToRender}
          value={checkboxGroupValue}
          disabled={isDisabled}
        />
      );
    }
    case QUESTION_TYPES.FIVE_NUMBER_RATING:
    case QUESTION_TYPES.TEN_NUMBER_RATING:
      return <NumberRating type={type} value={Number(answer)} />;
    case QUESTION_TYPES.CALENDAR: {
      const value = answer ? new Date(answer) : undefined;
      return (
        <DatePicker
          inputProps={{
            wrapperClassName: styles.datePicker,
            disabled: true,
          }}
          disabled
          placeholder="dd/mm/yy"
          value={isResponse ? value : undefined}
        />
      );
    }
    case QUESTION_TYPES.FILE: {
      const isImage = uploadType === FILE_TYPES.image;
      // DEV - Only Show image responses in case of manual scoring exams.
      const showImageResponse = isImage && isExam && !isAutoScoring;
      const ResponseComponent = showImageResponse ? (
        <ImageResonse url={answer} />
      ) : (
        <div className={styles.starRating}>
          <a href={answer}>{getRecordedContentFileName(answer)}</a>
        </div>
      );
      return isResponse ? (
        ResponseComponent
      ) : (
        <Button
          disabled={isDisabled}
          buttonWrapperClassName={styles.datePicker}
          startIcon={<img src={UploadIcon} />}
        >
          Upload file
        </Button>
      );
    }
    default:
      return null;
  }
};

export const Question = ({
  index,
  name,
  onEdit,
  onDelete,
  creationType,
  defaultExpanded,
  isDesktop,
}) => {
  const {
    values: {
      [examCreationFormKeys.questions]: questions,
      [QUESTIONNAIRE_FORM_KEYS.scoringType]: scoringType,
    },
  } = useFormState();

  const [expanded, setExpanded] = useState(defaultExpanded);
  const questionItem = questions[index];
  const {
    [QUESTION_KEYS.question]: question,
    [QUESTION_KEYS.questionType]: questionType,
    [QUESTION_KEYS.options]: options = [],
    [QUESTION_KEYS.changeType]: changeType,
    [QUESTION_KEYS.isMandatory]: isMandatory,
    [QUESTION_KEYS.totalMarks]: totalMarks,
  } = questionItem;
  const isDeleted = changeType === QUESTION_CHANGE_STATES.DELETED;
  const isOptionsTypeQuestions =
    questionType === QUESTION_TYPES.SINGLE_SELECT ||
    questionType === QUESTION_TYPES.MULTI_SELECT;
  const isExamCreationType = creationType === QUESTIONNAIRE_TYPES.EXAM;
  const isFormCreationType = creationType === QUESTIONNAIRE_TYPES.FORM;
  const isAutoScoring = scoringType === SCORING_TYPES.AUTOMATIC;
  const showFormQuestion =
    isFormCreationType || !isOptionsTypeQuestions || !isAutoScoring;
  const showAutoScoringQuestion =
    isExamCreationType && isOptionsTypeQuestions && isAutoScoring;

  if (isDeleted) return null;

  const questionTitle = isDesktop
    ? question
    : ellipsize(question, questionnaireConstants.questionTitleMaxLength);
  const questionDescription =
    options.length && isAutoScoring
      ? `| ${options.length} ${pluralise("option", options.length)}`
      : null;
  const correctOptions =
    questionType === QUESTION_TYPES.MULTI_SELECT
      ? options.reduce(
          (count, { [QUESTION_OPTION_KEYS.isCorrect]: isCorrect }) =>
            isCorrect ? count + 1 : count,
          0
        )
      : 1;

  return (
    <Draggable draggableId={name} index={index}>
      {(provided, snapshot) => (
        <div
          className={styles.question}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className={styles.question__number}>
            Q{index + 1}
            <div />
          </div>

          <div className={styles.question__card__wrapper}>
            <Accordion
              className={classnames(
                styles.question__card,
                snapshot.isDragging && styles.question__dragging
              )}
              expanded={expanded}
              onChange={(e, expanded) => setExpanded(expanded)}
              defaultExpanded
            >
              <QuestionSummary
                onEdit={() => onEdit?.(questionItem)}
                onDelete={() => onDelete?.(index, questionItem)}
                onExpand={() => setExpanded(!expanded)}
                expanded={expanded}
                isDesktop={isDesktop}
              >
                <div className={styles.question__title}>
                  {questionTitle}
                  {isMandatory && <span>*</span>}
                </div>

                <div className={styles.questionSubtitleCotnainer}>
                  <div className={styles.subLabel}>
                    <div
                      style={{
                        background: FORM_TYPE_OPTIONS[questionType].color,
                      }}
                    >
                      <img src={FORM_TYPE_OPTIONS[questionType].icon} />
                    </div>
                    {FORM_TYPE_OPTIONS[questionType].subLabel ||
                      FORM_TYPE_OPTIONS[questionType].label}
                  </div>

                  {creationType === QUESTIONNAIRE_TYPES.EXAM && (
                    <div className={styles.question__description}>
                      {questionDescription}
                      {questionType === QUESTION_TYPES.MULTI_SELECT &&
                        isAutoScoring &&
                        ` | ${correctOptions} correct`}
                      {!isAutoScoring &&
                        ` | ${totalMarks} ${pluralise("mark", totalMarks)}`}
                    </div>
                  )}
                </div>
              </QuestionSummary>
              <AccordionDetails>
                {showAutoScoringQuestion && (
                  <SingleOrMultipleSelectContent
                    options={options}
                    question={questionItem}
                    isAutoScoring={isAutoScoring}
                  />
                )}
                {showFormQuestion && <FormQuestion question={questionItem} />}
              </AccordionDetails>
            </Accordion>
          </div>

          <div
            className={styles.question__dragIcon}
            {...provided.dragHandleProps}
          >
            <DragIndicatorIcon />
          </div>
        </div>
      )}
    </Draggable>
  );
};

export const AddQuestionCta = ({ index, onClick }) => {
  return (
    <Draggable draggableId={"cta"} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div className={styles.addCtaWrapper}>
            <div className={styles.addCtaIndexElContainer}>
              <div className={styles.dummyQuestionNumber}>Q{index}</div>
              <div className={styles.addCtaIndexEl} />
            </div>
            <MuiButton
              className={styles.addCta}
              startIcon={<AddIcon />}
              onClick={onClick}
            >
              Add question
            </MuiButton>
          </div>

          <div className={classnames(styles.welcomeItem, "m-0")}>
            <div className={styles.addCtaIndexElContainer}>
              <div className={styles.dummyQuestionNumber}>Q{index}</div>
              <div className={styles.addCtaIndexEl} />
              <div className={styles.circle} />
            </div>
            <div className={styles.welcomeItem__title__reverse}>
              THANK YOU SCREEN
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export const DummyTitleItem = ({ id, index, title }) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          className={styles.welcomeItem}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className={styles.welcomeItem__dotContainer}>
            <div className={styles.circle} />
            <div className={styles.verticalBar} />
          </div>
          <div className={styles.welcomeItem__title}>{title}</div>
        </div>
      )}
    </Draggable>
  );
};
