import constants from "constants/constants";
import React from "react";

export const getLocationOptions = ({ liveFeatures, scheduleType }) => [
  {
    id: 7,
    label: "Exly Connect by Zoom",
    value: 7,
    icon: (
      <img
        alt="Exly Connect by Zoom"
        width="24px"
        src={require("assets/images/exly_con.png")}
      />
    ),
    hide:
      scheduleType === constants.scheduleTypesId.webinar ||
      !liveFeatures?.is_exlycon_active,
  },
  {
    id: 0,
    label: "Google Meet (link auto generated on booking)",
    value: 0,
    icon: (
      <img
        alt="Google Meet (link auto generated on booking)"
        width="24px"
        src={require("assets/images/meet_icon.png")}
      />
    ),
    hide: scheduleType === constants.scheduleTypesId.webinar,
  },
  {
    id: 4,
    label: "Zoom (link auto generated on booking)",
    value: 4,
    icon: (
      <img
        alt="Zoom (link auto generated on booking)"
        width="24px"
        src={require("assets/images/zoom_icon.png")}
      />
    ),
  },
  {
    id: 8,
    label: "Zoom Webinar",
    value: 8,
    icon: (
      <img
        alt="Zoom Webinar"
        width="24px"
        src={require("assets/images/zoom_icon.png")}
      />
    ),
    hide: scheduleType !== constants.scheduleTypesId.webinar,
  },
  {
    id: 1,
    label: "Online Meeting Link (Zoom, Meet, Skype, etc)",
    value: 1,
    icon: (
      <img
        alt="Online Meeting Link (Zoom, Meet, Skype, etc)"
        width="20x"
        src={require("assets/images/online-meeting.png")}
      />
    ),
  },
  {
    id: 2,
    label: "At my personal address",
    value: 2,
    icon: (
      <img
        alt="At personal address"
        width="20px"
        src={require("assets/images/personal-address.png")}
      />
    ),
    hide: scheduleType === constants.scheduleTypesId.webinar,
  },
  {
    id: 3,
    label: "At customer's address",
    value: 3,
    icon: (
      <img
        alt="At customer's address"
        width="18px"
        src={require("assets/images/customer-address.png")}
      />
    ),
    hide: scheduleType === constants.scheduleTypesId.webinar,
  },
  {
    id: 5,
    label: "Phone",
    value: 5,
    icon: (
      <img alt="Phone" width="24px" src={require("assets/images/phone.png")} />
    ),
    hide: scheduleType === constants.scheduleTypesId.webinar,
  },
  {
    id: 6,
    label: "WhatsApp",
    value: 6,
    icon: (
      <img
        alt="Whatsapp"
        width="24px"
        src={require("assets/images/whatsapp.png")}
      />
    ),
    hide: scheduleType === constants.scheduleTypesId.webinar,
  },
];

export const phone_field_names_based_on_address_types = {
  [constants.address_type_enum.phone]: "venue_details.contact_number",
  [constants.address_type_enum.whatsapp]: "venue_details.whatsapp_link",
};
