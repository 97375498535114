import { RichTextEditorUtils } from "@my-scoot/component-library-legacy";
import { change_states } from "constants/recordedContent";
import { useEffect, useRef } from "react";
import { useFormState } from "react-final-form";
import {
  DEFAULT_VARIANT_TYPE_OPTIONS,
  variantFormKeys,
} from "schedule-v2/commonPages/Components/MerchandisePriceAndStockDetails/components/Variant/data";
import {
  priceAndStockDetailsFormKeys,
  variantsFormKeys,
} from "schedule-v2/commonPages/Components/MerchandisePriceAndStockDetails/data";
import {
  variantChangeStates,
  variantDetailsFormKeys,
} from "schedule-v2/commonPages/Components/MerchandisePriceAndStockDetails/Modals/VariantDetailsModal/data";
import { magnitudeFormKeys } from "schedule-v2/commonPages/Components/MerchandiseShipping/components/HelperComponents/data";
import { shippingFormKeys } from "schedule-v2/commonPages/Components/MerchandiseShipping/data";
import {
  DEFAULT_AUTO_SAVE_DURATION,
  localStorageKeys,
} from "schedule-v2/commonPages/utils";
import { is_empty } from "utils/validations";
const { getHtmlFromEditorState } = RichTextEditorUtils;

/**
 *
 * @param {{ [variantCombinationKey: string]: object }} variantsData
 * @returns variants data array containing variant map
 */
export const parseAddedVariants = (variantsData = {}) => {
  const { [variantsFormKeys.VARIANTS]: variants } = variantsData;

  const addedVariants = [];
  if (!is_empty(variants)) {
    for (const [attributes, value] of Object.entries(variants)) {
      const variant_map = attributes.split(",").reduce((prev, current) => {
        const [key, value] = current.split("=");
        return { ...prev, [key]: value };
      }, {});
      addedVariants.push({ ...value, variant_map });
    }
  }
  return addedVariants;
};

export const parseVariantsDataPayload = (variantsData = {}) => {
  const { [variantsFormKeys.VARIANTS_DISPLAY_DATA]: variants_display_data } =
    variantsData;

  const variantsDisplayData = [];
  if (!is_empty(variants_display_data)) {
    // preparing data for variant type and options selectors
    for (const [type, options] of Object.entries(variants_display_data)) {
      if (DEFAULT_VARIANT_TYPE_OPTIONS.includes(type))
        variantsDisplayData.push({ type, options });
      else
        variantsDisplayData.push({
          type: "Custom",
          customType: type,
          options,
        });
    }
  }

  return {
    [variantsFormKeys.VARIANTS_DISPLAY_DATA]: variantsDisplayData, // this will be used to build all possible variations
    [variantsFormKeys.VARIATIONS]: parseAddedVariants(variantsData), // contains variants data received from database
  };
};

export const removeFields = (values, formFieldsEnum, shouldRemove) => {
  if (is_empty(values) || is_empty(formFieldsEnum)) return;
  Object.values(formFieldsEnum).forEach((formKey) => {
    let flag = true;
    if (shouldRemove) flag = shouldRemove(formKey);
    if (flag) delete values[formKey];
  });
};

export const getVariationsDataPayload = (values, hasVariants) => {
  let updatedValues = {};

  if (hasVariants) {
    // reset price and stock details form fields
    removeFields(values, priceAndStockDetailsFormKeys);
  } else {
    // reset variants data
    const { [variantsFormKeys.VARIATIONS]: variations = [] } = values;
    const prevVariations = [...variations];
    removeFields(
      values,
      variantsFormKeys,
      (fieldName) => fieldName !== variantsFormKeys.VARIANTS_DISPLAY_DATA
    );

    // add variants to be deleted
    // only those which are added in db
    const updatedVariations = prevVariations
      .filter((variation) => variation.uuid)
      .map(({ uuid }) => ({
        uuid,
        [variantDetailsFormKeys.CHANGE_STATE]: variantChangeStates.DELETED,
      }));

    values = {
      ...values,
      [variantsFormKeys.VARIATIONS]: is_empty(updatedVariations)
        ? undefined
        : updatedVariations,
      [variantsFormKeys.VARIANTS_DISPLAY_DATA]: undefined,
    };
  }

  const {
    [shippingFormKeys.ENABLE_SHIPROCKET]: enable_shiprocket,
    [shippingFormKeys.SHIPPING_METHOD]: shipment_method,
    [shippingFormKeys.SHIPPING_CHARGE]: shipping_charge,
    [shippingFormKeys.SHIPPING_CHARGE_INTERNATIONAL]:
      shipping_charge_international,
    [shippingFormKeys.SHIPPING_DAYS]: shipping_days,
    [shippingFormKeys.SHIPPING_DAYS_INTERNATIONAL]: shipping_days_international,

    [variantsFormKeys.VARIANTS_DISPLAY_DATA]: variants_data,
    [variantsFormKeys.VARIATIONS]: variations,
  } = values;

  if (enable_shiprocket) {
    const { weight, height, length, breadth } = values;
    updatedValues = {
      ...updatedValues,
      measurements: { weight, height, length, breadth },
      [shippingFormKeys.ENABLE_SHIPROCKET]: enable_shiprocket,
    };
  } else {
    updatedValues = {
      ...updatedValues,
      [shippingFormKeys.SHIPPING_DETAILS]: {
        shipping_charge,
        shipping_days,
        shipping_charge_international,
        shipping_days_international,
        shipment_method,
      },
    };
  }

  let variantsData = {};
  if (!is_empty(variants_data)) {
    variantsData = variants_data.reduce(
      (
        prev,
        {
          [variantFormKeys.TYPE]: type,
          [variantFormKeys.OPTIONS]: options,
          [variantFormKeys.CUSTOM_TYPE]: customType,
        }
      ) => ({
        ...prev,
        [customType || type]: options,
      }),
      {}
    );
  }
  updatedValues = {
    ...updatedValues,
    [variantsFormKeys.VARIANTS_DATA]: variantsData,
  };

  if (!is_empty(variations)) {
    updatedValues = {
      ...updatedValues,
      [variantsFormKeys.VARIATIONS]: variations,
    };
  }

  return updatedValues;
};

export const formErrorKeys = {
  VARIANTS: "variantsDataError",
};

export const validators = {
  variants: (formValues, page) => {
    const isPricingPage = page === 1;
    if (!isPricingPage) return;

    const {
      [variantsFormKeys.VARIATIONS]: variations,
      [variantsFormKeys.POSSIBLE_VARIATIONS]: possibleVariations,
      [shippingFormKeys.ENABLE_SHIPROCKET]: shiprocketEnabled,
      hasVariants,
    } = formValues;

    if (!hasVariants) return;

    if (is_empty(possibleVariations))
      return { [formErrorKeys.VARIANTS]: "Add a variant." };

    // validating if variants data is added for all available variants on ui
    const isVariantsAdded =
      variations?.filter(
        (variation) =>
          variation[variantDetailsFormKeys.CHANGE_STATE] !==
          change_states.deleted
      ).length === possibleVariations.length;

    if (shiprocketEnabled && hasVariants) {
      // validate if product weight & dimensions added for all variants if shiprocket is enabled
      const isVariantDimensionsAdded =
        variations?.filter((variation) => !!variation[magnitudeFormKeys.WEIGHT])
          .length === possibleVariations.length;

      if (!isVariantDimensionsAdded)
        return {
          [formErrorKeys.VARIANTS]: "Add Product Weight & Dimensions.",
        };
    }

    if (!isVariantsAdded)
      return { [formErrorKeys.VARIANTS]: "Add variants data." };
  },
};

export const formErrorMessageFormatter = (errors) =>
  errors[formErrorKeys.VARIANTS];

export const merchandiseUtils = {
  removeAutoSaveData: () => {
    localStorage.removeItem(localStorageKeys.FORM_STATE);
  },
  setAutoSaveData: (data) => {
    {
      delete data.booking_exists; // not an actual form value... backend value should be source of truth... should not be autosaved
      localStorage.setItem(localStorageKeys.FORM_STATE, JSON.stringify(data));
    }
  },
  getAutoSaveData: () =>
    JSON.parse(localStorage.getItem(localStorageKeys.FORM_STATE)),
};

export const useMerchandiseAutoSave = ({ isMerchandise }) => {
  const { values } = useFormState();

  const formStateRef = useRef(null);
  formStateRef.current = values;

  useEffect(() => {
    // auto save form state
    if (isMerchandise) {
      const interval = setInterval(() => {
        if (formStateRef.current) {
          let stateToSave = formStateRef.current;
          const { description } = stateToSave;
          stateToSave = {
            ...stateToSave,
            description: getHtmlFromEditorState(description),
          };
          merchandiseUtils.setAutoSaveData(stateToSave);
        }
      }, DEFAULT_AUTO_SAVE_DURATION);

      return () => clearInterval(interval);
    }
  }, [isMerchandise]);
};
