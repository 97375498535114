import React from "react";
import {
  ThemeProvider,
  creatorToolTheme,
  MobileModal,
} from "@my-scoot/component-library-legacy";

import useStyles from "../schedule-v2.module";

export default function MobileLayout({
  children,
  showPreview,
  onPreviewClose,
  isOnboarding,
  screenshot,
  screenshotClassName,
  hidePreviewSidebar,
  wideLayout,
  fullHeight = false,
  customScreenshotComponent,
}) {
  const classes = useStyles({ fullHeight });

  return (
    <ThemeProvider theme={creatorToolTheme}>
      <section
        className={`${classes.customLayout} ${
          isOnboarding ? classes.customLayout_onBoarding : ""
        }`}
      >
        <main
          id="mainLayout"
          className={`${classes.customLayout_main} ${
            isOnboarding ? classes.customLayout_main_onBoarding : ""
          }`}
        >
          <div
            className={
              !wideLayout
                ? classes.customLayout_main_content
                : classes.customLayout_main_content_wide
            }
          >
            {children}
          </div>
          <div className="py-3 d-md-hidden"></div>
        </main>
        {!hidePreviewSidebar && (
          <aside className={`d-none d-md-block ${classes.customLayout_aside}`}>
            <h4 className="mt-3">Preview</h4>
            <div className="text-muted mb-4">
              This is how your offering will look/appear.
            </div>

            {!customScreenshotComponent && (
              <img
                className={screenshotClassName}
                src={require(`../../assets/images/${
                  screenshot || "payment-page-sc.png"
                }`)}
                width="80%"
              />
            )}

            {customScreenshotComponent}
          </aside>
        )}
      </section>

      <MobileModal
        open={showPreview}
        header={"Preview"}
        primaryAction={{
          label: "Close",
          onClick: () => onPreviewClose(),
        }}
        onClose={() => onPreviewClose()}
      >
        <div className="p-3 text-center">
          This is how your offering will look/appear.
          {!customScreenshotComponent && (
            <img
              src={require(`../../assets/images/${
                screenshot || "payment-page-sc.png"
              }`)}
              width="80%"
              className="mx-auto mt-2"
            />
          )}
          {customScreenshotComponent}
        </div>
      </MobileModal>
    </ThemeProvider>
  );
}
