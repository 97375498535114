import React from "react";
import classnames from "classnames";
import { Switch } from "@my-scoot/component-library-legacy";
import styles from "./ZoomIsvDirectJoinToggle.module.css";
import { useForm, useFormState } from "react-final-form";

const ZoomIsvDirectJoinToggle = () => {
  const form = useForm();
  const {
    values: { metadata },
  } = useFormState();
  const { zoom_isv_direct_join } = metadata;
  const handleAllowOnlyAuthenticateCustomerChange = () => {
    form.change("metadata", {
      ...metadata,
      zoom_isv_direct_join: !zoom_isv_direct_join,
    });
  };

  return (
    <div className="pt-3 d-flex justify-content-between">
      <div
        className={classnames(
          "d-flex align-items-baseline",
          styles.single_label_info_style
        )}
      >
        <div className="d-flex flex-column">
          <div className={styles.customer_authentication_text}>
            Allow only authenticated customers to join
          </div>

          <div
            className={classnames(
              styles.customer_authentication_sub_text,
              "mt-2"
            )}
          >
            If the toggle is turned off, anyone with the link will be able to
            join.
          </div>
        </div>
      </div>

      <div className={styles.customer_authentication_switch}>
        <Switch
          /**
           * allowing authenticated user is
           * inversely proportional to zoom_isv_direct_join
           * i.e if true then we dont allow direct zoom isv join
           * */
          checked={!zoom_isv_direct_join}
          noIcon
          size="large"
          onChange={handleAllowOnlyAuthenticateCustomerChange}
        />
      </div>
    </div>
  );
};

export default ZoomIsvDirectJoinToggle;
